import { createSlice } from '@reduxjs/toolkit';

const PlayerStore = createSlice({
  name: 'Player',
  initialState: { isPlaying: false, playingMetaData: {}, metaData: [], index: 0, totalTrack: 0 },
  reducers: {
    playing(state, data) {
      state.index = data.payload.index
      state.playingMetaData = data.payload.metaData[data.payload.index];
      state.metaData = data.payload.metaData;
      state.totalTrack = data.payload.metaData.length;
      state.isPlaying = true
    },
    didPlayPrvSong(state) {
      if ((state.index - 1) >= 0) {
        state.index = state.index - 1
        console.log("NIL Set")
        state.playingMetaData = state.metaData[state.index];
        state.isPlaying = true
      } else {
        state.isPlaying = false
      }
    },
    didPlayNextSong(state) {
      if (state.totalTrack > (state.index + 1)) {
        console.log("NIL Set")

        state.index = state.index + 1
        state.playingMetaData = state.metaData[state.index];
        state.isPlaying = true
      } else {
        state.isPlaying = false
      }
    },
    toggle(state) {
      state.isPlaying = !state.isPlaying
    },
  },
});
export const playerAction = PlayerStore.actions;

export default PlayerStore;
