import { createSlice } from '@reduxjs/toolkit';

const AuthStore = createSlice({
  name: 'Auth',
  initialState: { isUserLogIn: localStorage.getItem(process.env.REACT_APP_IS_USER_LOGIN_KEY), userObject: JSON.parse(localStorage.getItem(process.env.REACT_APP_USER_KEY)) },
  reducers: {
    isUserLoginSucess(state, userObject) {
      state.isUserLogIn = true;
      state.userObject = userObject.payload;
      console.log(process.env.REACT_APP_API_KEY);
      localStorage.setItem(process.env.REACT_APP_USER_KEY, JSON.stringify(userObject.payload));
      localStorage.setItem(process.env.REACT_APP_API_KEY, userObject.payload.token);
      localStorage.setItem(process.env.REACT_APP_IS_USER_LOGIN_KEY, true);
      localStorage.setItem(process.env.REACT_APP_USer_Role, userObject.payload.role);
      localStorage.setItem(process.env.REACT_APP_USer_ID, userObject.payload.id);

    },
    logoutUser(state) {
      console.log("Commit")
      state.isUserLogIn = false;
      state.userObject = null;
      localStorage.removeItem(process.env.REACT_APP_USER_KEY);
      localStorage.removeItem(process.env.REACT_APP_IS_USER_LOGIN_KEY);
      localStorage.removeItem(process.env.REACT_APP_API_KEY);
      localStorage.removeItem(process.env.REACT_APP_USer_Role);
      localStorage.removeItem(process.env.REACT_APP_Search_History);
      localStorage.removeItem(process.env.REACT_APP_USer_ID);
    },
  },
});
export const authStoreAction = AuthStore.actions;

export default AuthStore;
