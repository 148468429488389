import { createSlice } from '@reduxjs/toolkit';

const UIStore = createSlice({
  name: 'UI',
  initialState: { isLoaderVisible: false, message: null, playListCount: 0, isNewNotication: false},
  reducers: {
    shouldShowLoader(state) {
      state.isLoaderVisible = true;
    },
    shouldHideLoader(state) {
      state.isLoaderVisible = false;
    },
    updatePlayListCount(state) {
      state.playListCount = state.playListCount + 1;
      state.isLoaderVisible = false;

    },
    updateNewNotificationCount(state , acton) {
      state.isNewNotication = acton.payload

    },
    shouldShowMessages(state, action) {
      state.message = {
        type:  action.payload.type,
        msg:  action.payload.msg,
        from:action.payload.from
      };
      state.isLoaderVisible = false;
    }
    ,
  },
});
export const UIStoreAction = UIStore.actions;

export default UIStore;