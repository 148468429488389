import { useSelector } from 'react-redux';
import React, { Fragment, Suspense } from "react";
import Loader from 'react-loader-spinner';
import { Route, Switch } from 'react-router-dom';
const HomeSharedData = React.lazy(() => import('./Components/Home/HomeSharedData'));
const LandingPage = React.lazy(() => import('./Components/LandingPage/LandingPage'));
const PlayListDeepManager = React.lazy(() => import('./DeepLinkManger/PlayListDeepManager'));
const MusicPlayerInterFace = React.lazy(() => import('./DeepLinkManger/MusicPlayerInterFace'));


const App = () => {
  const login = useSelector((state) => state.auth.isUserLogIn);
  const isLoaderVisible = useSelector((state) => state.ui.isLoaderVisible);
  const showLoaderView = () => {
    document.getElementById('loader').style.display = "flex"
  }
  const hideLoaderView = () => {
    document.getElementById('loader').style.display = "none"
  }
  const homeParam = { page: "Home", dict: {} }

  return (
    <Fragment>
      <Suspense fallback={<div className="loader-main">
        <div className="center">
          <Loader
            type="Audio"
            color="#5a20c4"
            height={100}
            width={100} //3 secs
          />
        </div>
      </div>}>
        {isLoaderVisible && showLoaderView()}
        {!isLoaderVisible && hideLoaderView()}

        <Switch>
          <Route path="/" exact>

            {login && <HomeSharedData navigate={homeParam} />}
            {!login && <LandingPage />}
          </Route>
          <Route path="/play-song/:TrackID" exact>
            <MusicPlayerInterFace isLogin={login} />

          </Route>
          <Route path="/playlist-song/:PlayListID" exact>
            <PlayListDeepManager isLogin={login} />
          </Route>
          <Route path="*">
            {login && <HomeSharedData navigate={homeParam} />}
            {!login && <LandingPage />}
          </Route>
        </Switch>
      </Suspense>

    </Fragment>
  );
}
export default App;

