import { configureStore } from '@reduxjs/toolkit';

import AuthStore from './AuthStore';
import UIStore from './UIStore';
import PlayerStore from './PlayerStore';


const store = configureStore({
  reducer: { ui: UIStore.reducer, auth: AuthStore.reducer, player: PlayerStore.reducer },
});

export default store;